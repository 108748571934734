<template>
    <v-card class="mt-6 pa-4 mr-2 ml-3" elevation="10">
        <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 pl-0">Ajouter une équipe</span>
                </v-toolbar>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
                <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loadingDialog"
                    @closeDialog="dialog = false"
                    titre="Ajouter une équipe"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col cols="12" md="12" lg="4" xl="4" >
                   <v-text-field
                        outlined
                        dense
                        filled
                        solo
                        rounded
                        :rules="[required('un nom d\'équipe')]"
                        v-model="nomEquipe"
                        label="Nom d'équipe"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4" >
                    <v-autocomplete
                        outlined
                        dense
                        filled
                        solo
                        rounded
                        :rules="[required('un chef d\'équipe')]"
                        :items="listeChef"
                        item-text="userName"
                        item-value="id"
                        v-model="chefEquipe"
                        label="Chef d'équipe"
                        @change="addTag(chefEquipe)"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="6" xl="6">
                    <v-autocomplete 
                        v-model="selectedVendeurs" 
                        :items="Vendeurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Vendeurs" 
                        placeholder="Trouver des vendeurs" 
                        prepend-icon="mdi-account-search-outline"
                        chips
                        deletable-chips
                        multiple
                        :rules="[requiredSelect()]"
                        >
                    
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="6" xl="6">
                    <v-autocomplete 
                        v-model="selectedProspecteurs" 
                        :items="Prospecteurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Prospecteur" 
                        placeholder="Trouver des prospecteurs" 
                        prepend-icon="mdi-account-search-outline"
                        chips
                        deletable-chips
                        multiple
                        :rules="[requiredSelect()]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6">
                    <v-btn @click="creerEquipe" color="info">créer l'équipe</v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-btn @click="$emit('ajouterEquipeMethod', false)" color="info">Annuler</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
    import axios from "axios";
    import Multiselect from 'vue-multiselect'
    import dialogeModal from '../../components/ui/dialogeModal'
    export default {
        name: "ajouterEquipeAdv",
        components: {
            dialogeModal:dialogeModal,
            Multiselect:Multiselect
        },
        data() {
            return {
                //dialoge delete
                idItemDeleted: null,
                dialogDelete:false,
                search: '',
                error: false,
                dialog: false,
                loadingDialog: false,
                messageDialogeSucces: "",
                messageDialogeError: "",
                nomEquipe: "",
                selectedVendeurs: [],
                selectedProspecteurs: [],
                chefEquipe:"",
                listeChef:[],
                listeEquipe: [],
                Vendeurs: [],
                Prospecteurs: [],
                isLoading: false,
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                requiredSelect(){
                    return (value) => (value.length>=0 || "Sélectionner au moins un utilisateur.")
                },
                rules: {
                    required: (value) => !!value || "Ce champ est obligatoire.",
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
            localStorage.setItem('typeUser', whoIam)
            }
            this.typeUser = localStorage.getItem("typeUser");
            this.getAllChef()
            this.getAllVendeurs()
            this.getAllProspecteur()
        },
        computed: {
            filteredList() {
                return this.listeEquipe.filter(post => {
                    return post.nomEquipe.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        methods: {
            emit(){
                this.$emit('ajouterEquipeMethod', true)
            },
            reinitialiser(){
                this.nomEquipe= ""
                this.chefEquipe=""
                this.listeChef=[]
                this.selectedVendeurs= []
                this.Vendeurs= []
                this.Prospecteurs= []
                this.selectedProspecteurs= []
                this.isLoading= false
                this.getAllChef()
                this.getAllVendeurs()
                this.getAllProspecteur()
            },
            openSupprimerEquipe(id){
                this.idItemDeleted = id
                this.dialogDelete = true
            },
            async supprimerEquipe(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idEquipe', this.idItemDeleted)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'supprimerEquipe/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    this.dialogDelete = false
                    if(res.data.error == false){
                        this.dialog=true
                        this.loadingDialog=false
                        this.error = false
                        this.messageDialogeSucces= res.data.message
                        this.messageDialogeError= ""
                    }else if(res.data.error == true){
                        this.dialog=true
                        this.loadingDialog=false
                        this.error = true
                        this.messageDialogeSucces= ""
                        this.messageDialogeError= res.data.message
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.getAllChef()
                    this.getAllVendeurs()
                    this.getAllProspecteur()
                }).catch((err)=>{
                    this.dialogDelete = false
                    this.reinitialiser()
                    this.getAllChef()
                    this.getAllVendeurs()
                    this.getAllProspecteur()
                })
            },
            async creerEquipe(){
                this.$refs.form.validate();
                if (this.$refs.form.validate()) {
                    this.dialog=true
                    this.loadingDialog=true
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('nomEquipe', this.nomEquipe)
                    formData.append('chefEquipe', this.chefEquipe)
                    formData.append('vendeurs', this.selectedVendeurs)
                    formData.append('prespecteurs', this.selectedProspecteurs)
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'createEquipe/',
                        method: 'POST',
                        data: formData,
                    }).then((res)=>{
                        if(res.data.error == false){
                            this.loadingDialog=false
                            this.error = false
                            this.messageDialogeSucces= res.data.message
                            this.messageDialogeError= ""
                            this.reinitialiser()
                        }else if(res.data.error == true){
                            this.loadingDialog=false
                            this.error = true
                            this.messageDialogeSucces= ""
                            this.messageDialogeError= res.data.message
                        }else if(res.data.error == "permission"){
                            alert(res.data.message)
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        this.getAllChef()
                        this.getAllVendeurs()
                        this.getAllProspecteur()
                    }).catch((err)=>{
                        console.log(err)
                        this.reinitialiser()
                        this.getAllChef()
                        this.getAllVendeurs()
                        this.getAllProspecteur()
                    })
                }
                this.emit()
            },
            async getAllChef(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefEquipe/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChef = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllVendeurs(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllVendeurs/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Vendeurs = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllProspecteur(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllProspecteur/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Prospecteurs = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            addTag (newTag) {
                var index = this.Vendeurs.findIndex(x => x.id === newTag)
                if (index > -1) { 
                    this.Vendeurs.splice(index, 1);
                }
                
                var index = this.Prospecteurs.findIndex(x => x.id === newTag)
                if (index > -1) { 
                    this.Prospecteurs.splice(index, 1);
                }
                var index = this.selectedProspecteurs.findIndex(x => x === newTag)
                if (index > -1) { 
                    this.selectedProspecteurs.splice(index, 1);
                }
                var index = this.selectedVendeurs.findIndex(x => x === newTag)
                if (index > -1) { 
                    this.selectedVendeurs.splice(index, 1);
                }
            }
        }
    }
</script>