<template>
  <div
    class="file-upload"
    @click="handleClick"
    @dragover.prevent="isDragging = true"
    @dragleave.prevent="isDragging = false"
    @drop.prevent="onDrop"
    :class="{ 'is-dragging': isDragging }"
  >
    <input
      type="file"
      ref="fileInput"
      accept="application/pdf"
      @change.prevent="onFileSelected"
      style="display: none"
    >
    <div class="fileInputArea">
      <v-icon x-large>cloud_upload</v-icon>
      <p>
        Déposer votre/vos fichier(s) ici <span><br />(ou)</span>
      </p>
      <p style="background-color: #e2e2e2">Séléctionnez un fichier à importer</p>
      <p>Types de fichier supportés : pdf</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isDragging: false
    }
  },
  methods: {
    handleClick(e) {
      this.$refs.fileInput.click();
    },
    onDrop(e) {
      this.isDragging = false
      this.handleFiles(e.dataTransfer.files)
    },
    onFileSelected(e) {
      this.handleFiles(e.target.files)
    },
    handleFiles(files) {
      // Convert FileList to Array
      const fileList = Array.from(files)
      // Emit the file list to parent component
      this.$emit('files-uploaded', { target: { files: fileList } })
      // Reset the input
      this.$refs.fileInput.value = ''
    }
  }
}
</script>
<style scoped>
.fileInputArea{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #ccc;
  border-radius: 8px;
}
.file-upload {
  width: 100%;
  height: 200px;
  
  transition: all 0.3s ease;
}
.file-upload.is-dragging {
  border-color: #2196F3;
  background-color: #E3F2FD;
}
.file-upload p {
  margin-bottom: 10px;
}
.file-upload button {
  padding: 8px 16px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.file-upload button:hover {
  background-color: #1976D2;
}
</style>