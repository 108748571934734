<template>
    <div>
         <b-table
                id="table-graph"
                show-empty
                empty-text="Aucune donnée disponible pour le moment"
                striped
                bordered
                :items="itemsTable"
                class="pr-lg-4 px-0"
                :fields="headers"

                >
                    <template #empty="scope">
                        </noDataTable>
                    </template>    
                    <template  #cell(categorie)="data">
                        <span>{{data?.value}}</span>
                    </template>
                    <template #cell(today)="data">
                        <span>{{data?.value}} €</span>
                    </template>
                    <template #cell(week)="data">
                        <span v-if="data?.value < 0" class="font-weight-bold green--text">{{data?.value}} %</span>
                        <span v-else class="font-weight-bold red--text">{{data?.value}} %</span>
                    </template>
                    <template #cell(month)="data">
                        <span v-if="data?.value < 0" class="font-weight-bold green--text">{{data?.value}} %</span>
                        <span v-else class="font-weight-bold red--text">{{data?.value}} %</span>
                    </template>
                    <template #cell(year)="data">
                        <span v-if="data?.value < 0" class="font-weight-bold green--text">{{data?.value}} %</span>
                        <span v-else class="font-weight-bold red--text">{{data?.value}} %</span>
                    </template>
              </b-table>
    </div>
</template>
<script>
import noDataTable from "../../../components/no-data-table.vue"
export default {
    name:'chart-table',
    components:{
            noDataTable:noDataTable,
    },
    props:['itemsTable'],
    data(){
        return{
            headers:[
                {label:'Categorie',key:"categorie",thStyle:"background-color:#103A5E;color:white;"},
                {
                  label:"Prix du jour",
                  key:"today",
                  thStyle:"background-color:#103A5E;color:white;"
                },
                {
                  label:"semaine derniere",
                  key:"week",
                  thStyle:"background-color:#103A5E;color:white;"
                },
                {
                  label:"mois dernier",
                  key:"month",
                  thStyle:"background-color:#103A5E;color:white"
                },
                {
                  label:"an dernier",
                  key:"year",
                  thStyle:"background-color:#103A5E;color:white"
                },
            ],
        }
    }
}
</script>
<style >
@import "../../../components/dataTable/style.css";
    @media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
     #table-graph td:nth-of-type(1):before { content: "Categorie"; }
	 #table-graph td:nth-of-type(2):before { content: "Prix du jour"; }
	 #table-graph td:nth-of-type(3):before { content: "Prix semaine derniere"; }
	 #table-graph td:nth-of-type(4):before { content: "Prix mois dernier"; }
	 #table-graph td:nth-of-type(5):before { content: "Prix l'an dernier"; }
}
</style>