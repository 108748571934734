
<template>
   
    <div style="height:100%">
    <div style="display: flex; justify-content: flex-end;">
        <v-btn class="mr-5 primary" @click="downloadMyRapport">Exporter mes demandes</v-btn>
    </div>
   
        <datatable id="table-mes-cotations"  class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy" @search="rechercheData">
            <template v-slot:header>
                <div style="width:200px;" class="float-end container-div" >
                    <select v-model="filterStatus" class="form-control options-container" id="sel1" @change="filterByStatus">
                        <option value="tout">Toutes les demandes</option>
                        <option value="1">acceptée</option>
                        <option value="0">en attente</option>
                        <option value="2">refusée</option>
                    </select>
                </div>
            </template>
            <template v-slot:body>
                <tbody v-if="projects.length > 0">
                    <tr class="text-center" v-for="(project, index) in projects" :key="project.id">
                        <td>{{project.date}}</td>
                        <td>{{project.Commerciale}}</td>
                        <td>
                            <div class="td-table">
                                <v-tooltip v-if="project.Raison.length>=20" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <small class="td-table" v-bind="attrs" v-on="on">
                                            <router-link :to="{path: '/mes-profils-societes', query: { id: project.idSociete }}">{{project.Raison.substring(0, 20)+"..."}}</router-link> 
                                        </small>
                                        <small>{{project.siret}}</small>
                                    </template>
                                    <span>{{project.Raison}}</span>
                                </v-tooltip>
                                <small class="td-table" v-else>
                                    <router-link :to="{path: '/mes-profils-societes', query: { id: project.idSociete }}">{{project.Raison}}</router-link>
                                    <small>{{project.siret}}</small>
                                </small>
                            </div>
                        </td>
                        <td>
                            <div v-if="project.typeDemande == 'ELEC'" class="td-table">
                                <v-icon  color="orange darken-1" class="mr-2">mdi-lightning-bolt-circle</v-icon>{{project.typeDemande}}
                            </div>
                            <div v-else class="td-table">
                                <v-icon  color="blue darken-4" class="mr-2">mdi-fire-circle</v-icon>{{project.typeDemande}}
                            </div>
                        </td>
                        <td class="text-center">
                            <div style="max-width:200px">
                            
                                <small>{{spliteList(project.numCompteur)[0]}}</small>
                                <v-icon
                                    v-if="spliteListSlice(project.numCompteur).length>0"
                                    large
                                    data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                    right
                                    >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseCompteur'+index">
                                    <div >
                                        <small v-for="c in spliteListSlice(project.numCompteur)">
                                            {{c}}
                                        </small>    
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div >
                                <div v-if="project.fileUrl1!='' && project.fileUrl1!='undefined' && project.fileUrl1!=null">
                                    <small >
                                        <a :href="project.fileUrl1" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 1</span></a>
                                    </small>
                                    <v-icon
                                        v-if="project.fileUrl2!=''&& project.fileUrl2!='undefined' && project.fileUrl2!=null"
                                        data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                        >
                                        mdi-menu-down
                                    </v-icon>
                                </div>
                                <div class="collapse multi-collapse" :id="'multiCollapseCompteur'+index">
                                    <div  class="mr-5" v-if="project.fileUrl2!=''&& project.fileUrl2!='undefined' && project.fileUrl2!=null">
                                        <small>
                                            <a :href="project.fileUrl2" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 2</span></a>
                                        </small>
                                    </div>
                                    <div v-if="project.fileUrl3!=''&& project.fileUrl3!='undefined' && project.fileUrl3!=null">
                                        <small>
                                            <a :href="project.fileUrl3" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 3</span></a>
                                        </small>
                                    </div>
                                    <div v-if="project.fileUrl4!=''&& project.fileUrl4!='undefined' && project.fileUrl4!=null">
                                        <small>
                                            <a :href="project.fileUrl4" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 4</span></a>
                                        </small>
                                    </div>
                                    <div v-if="project.fileUrl5!=''&& project.fileUrl5!='undefined' && project.fileUrl5!=null">
                                        <small>
                                            <a :href="project.fileUrl5" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 5</span></a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div style="max-width:200px">
                                <div v-if="project.ACD.length>0">
                                    <small>
                                        <a :href="project.ACD[0]" target="_blank"><span class="text-body-4 blue--text">ACD 1</span></a>
                                    </small>
                                    <v-icon
                                        v-if="project.ACD.length>1 || project.Facture.length>0 || project.Contrat.length>0"
                                        data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                        >
                                        mdi-menu-down
                                    </v-icon>
                                </div>
                                <div v-else-if="project.Facture.length>0">
                                    <small>
                                        <a :href="project.Facture[0]" target="_blank"><span class="text-body-4 blue--text">Facture 1</span></a>
                                    </small>
                                    <v-icon
                                        v-if="project.ACD.length>0 || project.Facture.length>1 || project.Contrat.length>0"
                                        data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                        >
                                        mdi-menu-down
                                    </v-icon>
                                </div>
                                <div v-else-if="project.Contrat.length>0">
                                    <small>
                                        <a :href="project.Contrat[0]" target="_blank"><span class="text-body-4 blue--text">Contrat 1</span></a>
                                    </small>
                                    <v-icon
                                        v-if="project.ACD.length>0 || project.Facture.length>0 || project.Contrat.length>1"
                                        data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                        >
                                        mdi-menu-down
                                    </v-icon>
                                </div>
                                <div class="collapse multi-collapse mr-5" :id="'multiCollapseCompteur'+index">
                                    <div v-for="acd, index in project.ACD">
                                        <small>
                                            <a :href="acd" target="_blank"><span class="text-body-4 blue--text">ACD {{index+1}}</span></a>
                                        </small>
                                    </div>
                                    <div v-for="Facture,index in project.Facture">
                                        <small>
                                            <a :href="Facture" target="_blank"><span class="text-body-4 blue--text">Facture {{index+1}}</span></a>
                                        </small>
                                    </div>
                                    <div v-for="Contrat,index in project.Contrat">
                                        <small>
                                            <a :href="Contrat" target="_blank"><span class="text-body-4 blue--text">Contrat {{index+1}}</span></a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>{{project.Echeance}}</td>
                        <td style="text-align: center;" >
                            <small v-if="project.valide == '4'"><v-icon dense style="color: orange;">mdi-close-circle</v-icon><b class="ml-2">annuler</b></small>
                            <small v-if="project.valide == '3'"><v-icon dense style="color: orange;">mdi-close-circle</v-icon><b class="ml-2">ACD expiré</b></small>
                            <small v-if="project.valide == '2'"><v-icon dense style="color: red;">mdi-close-circle</v-icon><b class="ml-2">refusée</b></small>
                            <small v-if="project.valide == '1'"><v-icon dense style="color: #00E676;">mdi-check-circle</v-icon><b class="ml-2">acceptée</b></small>
                            <small v-if="project.valide == '0'"><v-icon dense  style="color: gray;">mdi-timer-refresh-outline</v-icon><b class="ml-2">en attente</b></small>
                        </td>
                    </tr>
                </tbody>
                <progressCircular class="ma-8" v-else-if='loadingTable == true'/>
                <noDataTable style="z-index:2" class="ma-4" v-else-if="projects?.length == 0 && loadingTable == false"/>
            </template>
        </datatable>
        <v-row>
            <v-col sm="11">
                <pagination v-if="projects?.length > 0" :pagination="pagination"
                    @selectNumberOfRecords="rechercheData"
                    @first="getData(pagination.firstPage)"
                    @last="getData(pagination.lastPage)"
                    @prev="getData(pagination.prevPageUrl)"
                    @current="getData(pagination.currentPage)"
                    @next="getData(pagination.nextPageUrl)">
                </pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import noDataTable from "../../components/no-data-table.vue"
    import progressCircular from "../../components/progress-circular.vue"
    import axios from 'axios'
    export default{
        name: 'mesDemandesDeCotation',
        components: {datatable:Datatable, pagination:Pagination,noDataTable,progressCircular},
        data(){
            let sortOrders={};
            let columns = [
                {width:'13vw',label:"Date d'ajout", name:'date', sortable: true},
                {width:'13vw',label:'Commercial', name:'Commerciale', sortable: true},
                {width:'13vw',label:'Raison Sociale', name:'Raison', sortable: true},
                {width:'13vw',label:'Energie', name:'typeDemande',sortable: true},
                {width:'13vw',label:'Compteur', name:'numCompteur', sortable: true},
                {width:'13vw',label:'Fichiers', sortable: true},
                {width:'13vw',label:'Début de contrat', name:'dateDebut', sortable: true},
                {width:'13vw',label:'Statut', name:'status', sortable: true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                loadingTable:false,
                filterStatus: 'tout',
                projects: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '20', '30'],
                tableData: {
                    statusFilter: "",
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                pagination: {
                    perPage: ['15', '20', '30'],
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },
            }
        },
        async created() {
            this.loadingTable = true;
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getData();
            this.loadingTable = false;
        },
        methods: {
            downloadMyRapport(){
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'exportDemandeDeCotationByCompte/',
                    method: 'POST',
                    responseType: 'arraybuffer',
                    data: {'token': this.getToken('token')},
                })
                .then(response => {
                    if(response.data.result =="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }else{
                        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'mesDemandesDesCotations.xls');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
            filterByStatus(){
                var pageNumber = 0
                if(this.filterStatus != 'tout'){
                    this.tableData.statusFilter = this.filterStatus
                    this.getData(0)
                }else{
                    this.tableData.statusFilter = ""
                    this.getData(0)
                }
            },
            rechercheData({type, key}) {
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesDemandeDeCotationServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                        if(response.data.result == true){
                            let data = response.data.data;
                            if (this.tableData.draw == data.draw) {
                                this.projects = data.data.data;
                                this.configPagination(data.data);
                            }
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            },
            async getData(pageNumber = 0) {
                this.tableData.draw++;
                await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesDemandeDeCotationServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                        if(response.data.result == true){
                            let data = response.data.data;
                            if (this.tableData.draw == data.draw) {
                                this.projects = data.data.data;
                                this.configPagination(data.data);
                            }
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            },
            spliteList(list){
                return list.split(',')
            },
            spliteListSlice(list){
                return list.split(',').slice(1,)
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },
        }
    }
</script>
<style>
    #table-mes-cotations th{
        background-color:#103A5E;
        color:white;
        font-weight:bold;
    }
</style>
<style scoped>
@media only screen and (max-width:400px){
    .container-div{
        width:125px !important;
        padding:0px;
    }
}
    @import "../../components/dataTable/style.css";
     @media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    td:nth-of-type(1):before { content: "Date d'ajout"; }
	td:nth-of-type(2):before { content: "Commercial"; }
	td:nth-of-type(3):before { content: "Raison Sociale"; }
	td:nth-of-type(4):before { content: "Energie"; }
	td:nth-of-type(5):before { content: "Compteur"; }
	td:nth-of-type(6):before { content: "Pièce jointe"; }
	td:nth-of-type(7):before { content: "Début de contrat"; }
	td:nth-of-type(8):before { content: "Statut"; }
}

    .td-table, .td-valeur, .td-statut{
        display:flex;
        flex-direction: column;
        justify-content:center;
    }
</style>
