<template>
    <div style="background-color: #eee;">
            <v-card style="overflow: hidden;">
                 <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">Creation Lettre Résiliation</span>
                </v-toolbar>
                <v-card-text class="my-6">
                    <v-form ref="formAcd"  lazy-validation>
                        <v-row>
                            <v-col md="6">
                                <v-text-field v-model="societe.Raison" label="Raison Sociale" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="societe.AdressePostal" label="Adresse Societe" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="societe.nom" label="Nom client" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="societe.prenom" label="Prénom client" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field type="date" v-model="dataFournisseur.dateResiliation" label="Date Résiliation" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="dataFournisseur.nomFournisseur" label="Nom Fournisseur" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="dataFournisseur.adresseFournisseur" label="Adresse Fournisseur" 
                                    :rules="[rules.required]" outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="dataFournisseur.compteFacturation" label="Compte de facturation" 
                                    outlined/>
                            </v-col>
                            <v-col md="6">
                                <v-autocomplete 
                                    v-model="selectedCompteur" 
                                    :items="listCompteur" 
                                    :loading="isLoading"
                                    hide-no-data 
                                    hide-selected
                                    :rules="[rules.required]"
                                    label="Autre compteur" 
                                    placeholder="Ajouter un compteur" 
                                    prepend-icon="mdi-account-search-outline"
                                    chips
                                    item-text="numCompteur"
                                    deletable-chips
                                    multiple
                                    return-object
                                    >
                                    <template v-slot:item="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggle">
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-content-end"> 

                            <v-btn
                                :color="$colors[0]"
                                class="mt-8 mr-4 text-white"
                                @click="createLettreResiliation"
                                >
                                Valider
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts, TextAlignment, setFillingRgbColor, setFontAndSize , layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import axios from 'axios'
    import 'jspdf-autotable'
    export default {
        name: "lettreDeRisiliation",
        props:{'societe':'', 'listCompteur':[]},
        data() {
            return{
                openModalAcdEngie: true,
                isLoading: false,
                dataFournisseur: {
                    nomFournisseur: '',
                    adresseFournisseur: '',
                    dateResiliation: '',
                    compteFacturation: '',
                },
                selectedCompteur: [],
                rules: {
                    required: value => (value!=null && (""+value).length>0)|| 'Ce champ est obligatoire.',
                    requiredString: value => (""+value).length>8|| 'Ce champ est obligatoire.',
                    siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
                    isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                    isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                    phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                    phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
                    
                    emailRules: [
                    v => !!v || 'E-mail obligatoire',
                    v => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
                    ],
                },
                required(fieldName) {
                    return (value) => ((!!value || value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
            }
        },
        methods:{
            closeModal(){
                this.$emit('closeResiliationModal');
            }, 
            toggle(){
                this.selectedCompteur = [...this.listCompteur]
            },
            async createLettreResiliation(){
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/resiliation/resiliation.pdf'
                var pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                var pageZeroDoc = await PDFDocument.load(pageZero)
                
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                var form = pageZeroDoc.getForm()
                var field = form.getTextField('infoSociete')
                var txt = this.societe.Raison + " \n"+this.societe.AdressePostal
                field.setText(txt)
                field.setFontSize(10.5)
                var field = form.getTextField('dateAjd')
                var DateAujourdhui = new Date();
                 DateAujourdhui = DateAujourdhui.getDate()+"/"+(DateAujourdhui.getMonth()+1)+"/"+DateAujourdhui.getFullYear()
                field.setText(DateAujourdhui)
                field.setFontSize(10.5)
                var field = form.getTextField('infofour')
                
                var txt = this.dataFournisseur.nomFournisseur+ ' \n'+ this.dataFournisseur.adresseFournisseur;
                field.setText(txt)
                field.setFontSize(10.5)

                var typeDemande = ""
                var typeD =""
                var nPointLivraison = ""
                var nPointLivraisonGAZ = ""
                for (var i=0; i<this.selectedCompteur.length; i++){
                    if(this.selectedCompteur[i]['typeDemande'] == "GAZ"){
                        nPointLivraisonGAZ = nPointLivraisonGAZ +this.selectedCompteur[i]['numCompteur']+ ", "
                        

                    }else{
                        nPointLivraison = nPointLivraison +this.selectedCompteur[i]['numCompteur']+ ", "
                    }
                }
                if(nPointLivraisonGAZ != "" && nPointLivraison != "" ){
                    typeDemande = "GAZ et ELEC"
                    typeD = "d'éléctricité et de GAZ"
                }else if(nPointLivraisonGAZ != "" ){
                    typeDemande = "GAZ"
                    typeD = "de GAZ"
                }else if(nPointLivraison != "" ){
                    typeDemande = "ELEC"
                    typeD = "d'éléctricité "
                }
                txt = `Objet : Résiliation de nos contrats de fourniture ${typeDemande} à date anniversaire le `+
                    this.dateFormatSlach(this.dataFournisseur.dateResiliation)
                var field = form.getTextField('objet')
                field.setText(txt)
                field.setFontSize(10)
                var txt = "Madame, Monsieur,\n\n"+
                    "Nous vous notifions par la présente, notre souhait de ne pas poursuivre nos relations contractuelles à "+
                    `l'échéance de nos contrats de fourniture ${typeD} souscrit auprès de votre société : \n\n`+
                    "Compte de facturation : "+ this.dataFournisseur.compteFacturation+ " \n\n"+
                    "N° du point de livraison ELEC :"+nPointLivraison+ "\n\n"+
                    "N° du point de livraison GAZ :"+nPointLivraisonGAZ+ "\n\n"+
                    "Date de résiliation : "+this.dateFormatSlach(this.dataFournisseur.dateResiliation)+" à minuit.\n\n"+
                    "Contractant du Site :"+this.societe.Raison+"\n\n"+
                    "Cette demande de résiliation respecte le préavis de résiliation contractuel de 45 jours. \n"+
                    "En conséquence, conformément aux dispositions dudit contrat, celui-ci sera résilié de plein droit le:"+
                    this.dateFormatSlach(this.dataFournisseur.dateResiliation)+" à minuit.\n\n"+
                    "Nous vous serions reconnaissants de nous faire parvenir, par retour, un accusé de réception de cette "+
                    "demande de résiliation et de nous confirmer que vous respecterez vos obligations contractuelles de "+
                    "fourniture jusqu’à cette date.\n\n"+
                    "Nous vous prions d’agréer, Madame, Monsieur, l’expression de nos salutations distinguées.\n\n"+
                    this.societe.nom + " "+this.societe.prenom
                const textLines = txt.split('\n');
                const lineHeight = 20; // Adjust this value to set the line spacing
                const pageWidth = firstPage.getWidth();
                const pageHeight = firstPage.getHeight();
                let startingPositon = pageHeight - 250; // Adjust the starting Y coordinate
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                var multiText = layoutMultilineText(txt, {
                    alignment: 'left', 
                    fontSize: 11.2,   
                    font: timesRomanFont,
                    lineHeight: lineHeight,
                    bounds: { width: pageWidth - 80, height: pageHeight  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    if (startingPositon - lineHeight < 30) {
                        var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
                        var newPageFirstTextY = pageHeight - 50;
                        startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 50,
                            y: startingPositon,
                            size: 11.2,
                            maxWidth: pageWidth - 80,
                            color: rgb(0, 0, 0),
                        })
                    }else{
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 50,
                            y: startingPositon,
                            size: 11.2,
                            maxWidth: pageWidth - 80,
                            color: rgb(0, 0, 0),
                        });
                    }
                    // move position down
                    startingPositon = startingPositon - lineHeight
                }
                
                var fields = form.getFields()
                fields.forEach(field => {
                    field.enableReadOnly()
                })
                form.updateFieldAppearances()
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)

                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, 'Lettre_de_résiliation.pdf', 'application/pdf')
            },
            dateFormatSlach(date){
                try{
                    return date.split('-').reverse().join('/')
                }catch(err){
                    return date
                }
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
        }
    }

</script>