<style scoped>
    .width-text-field{
        width:30%
    }
    .width-button-responsive{
        width:25%
    }

    @media only screen and (max-width:500px){
        .width-text-field{
            width:90%
        }
        .width-button-responsive{
            width:75%,
        }
    }
</style>
<template>
    <div class="ma-4">
    <!-- la form pricipale -->
    <v-card class="mt-6"   elevation="5">
    <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 pl-0">Autorisation de Collecte de Données</span>
                </v-toolbar>
      <v-row>
        <loadingComponent @closeDialog="dialog = $event" :loadingProps="loading" :dialogProps="dialog" :messageDialogeProps="messageDialoge" :errorProps="error"/>
      </v-row>
            <div style='d-flex' class="ma-0"> 
            <div class="d-flex  flex-row my-8">
                
                <v-form class='width-text-field ma-8 mb-0' ref="formSiren" lazy-validation>
                            <v-text-field
                                    outlined
                                    :rules="[rules.required, rules.sirenLength, rules.isNumber]"
                                    v-model="siren"
                                    label="Siren"
                                    @input="inseeResponse=false, siretFound=null"
                                    @keydown.enter.prevent="getSiretData"
                            ></v-text-field>
                            <v-btn
                                @click="getSiretData"
                                depressed
                                elevation="1"
                                large
                                :color='$colors[0]'
                                class="ma-auto text-white"
                            >
                                Rechercher
                            </v-btn>
                </v-form>
                <v-form   v-if="siretFound == 1 || siretFound == 2" class="width-text-field ma-8 mb-0" ref="formSiret" lazy-validation>
                            <v-combobox
                                ref="siret"
                                :search-input.sync="siret"
                                @input="inseeResponse=false"
                                outlined
                                :rules="[rules.required, rules.siretLength, rules.isNumber]"
                                item-disabled="disable"
                                item-text="name"
                                item-value="name"
                                :items="siretExist"
                                :return-object="false"
                                label="Siret"
                            ></v-combobox>
                            <!--<v-text-field
                                    v-if="siretFound == 2"
                                    outlined
                                    :rules="[rules.required, rules.siretLength, rules.isNumber]"
                                    v-model="siret"
                                    label="Siret"
                            ></v-text-field>-->
                            <v-btn
                                v-if="siretFound == 1 || siretFound == 2"
                                @click="rechercheParSiret"
                                depressed
                                elevation="1"
                                :color='$colors[0]'
                                large
                                class="ma-auto text-white"
                            >
                                Valider
                            </v-btn>
                </v-form>
            </div>
                <v-col class="pa-4" cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-divider v-if="siretInccorect == false && inseeResponse==true"></v-divider>  
                </v-col>                 
                <v-col cols="12" sm="10" md="10" lg="10" xl="12" v-if="siretInccorect == false && inseeResponse==true">
                    <v-form class="ma-4" ref="formUpload" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="raison"
                                        label="Raison sociale"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="adressePostale"
                                        label="Adresse"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required, rules.phoneLength, rules.isNumber]"
                                        v-model="num_tel"
                                        label="Numéro de téléphone"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required,rules.isString]"
                                        v-model="nom"
                                        label="Nom"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required,rules.isString]"
                                        v-model="prenom"
                                        label="Prénom"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required,rules.emailRules]"
                                        v-model="email"
                                        label="adresse e-mail"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required,rules.isString]"
                                        v-model="fonction"
                                        label="Poste"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="RCS"
                                        label="RCS"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pa-4" cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-divider v-if="siretInccorect == false && inseeResponse==true"></v-divider>  
                            </v-col>
                            <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                                <v-radio-group
                                    v-model="typeContrat"
                                    row
                                >
                                    <v-radio
                                        label="GAZ"
                                        value="gaz"
                                        @click="choisirGaz"
                                        
                                    ></v-radio>
                                    <v-radio
                                        label="ELECTRICITÉ"
                                        value="elec"
                                        @click="choisirElec"
                                    ></v-radio>
                                    <v-radio
                                        label="ELECTRICITÉ & GAZ"
                                        value="elec_et_gaz"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col> 
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-row justify="space-between">
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4" 
                                        v-if="typeContrat === 'gaz' || typeContrat === 'elec_et_gaz'">
                                        
                                        <v-text-field
                                            outlined
                                            :rules="[rules.required, rules.isGazCompteur]"
                                            v-model="num_comp_gaz[0]"
                                            label="Numéro de compteur gaz n°1"
                                        >
                                        </v-text-field>
                                        <v-text-field
                                            outlined
                                            :rules="[rules.required, rules.isGazCompteur]"
                                            v-for="index in lengthCompteurgaz"
                                            :key="index"
                                            v-model="num_comp_gaz[index]"
                                            :label="'Numéro de compteur gaz n°'+(index+1)"
                                        >
                                        </v-text-field>
                                        <v-btn
                                            depressed
                                            elevation="1"
                                            large
                                            class="responsiveBtn ma-auto text-white"
                                            width="45%"
                                            :color='$colors[0]'
                                            @click="ajouterCompteurGaz"
                                        >
                                            Ajouter
                                        </v-btn>
                                        <v-btn
                                            v-if="lengthCompteurgaz > 0"
                                            depressed
                                            elevation="1"
                                            large
                                            class="error responsiveBtn ma-auto text-capitalize ml-2"
                                            width="45%"
                                            @click="supprimerCompteurGaz"
                                        >
                                            Supprimer
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4" 
                                        v-if="typeContrat === 'elec' || typeContrat === 'elec_et_gaz'">
                                        <v-text-field
                                            outlined
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-model="num_comp_elec[0]"
                                            label="Numéro de compteur ELEC n°1"
                                        ></v-text-field><v-text-field
                                            outlined
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-for="index in lengthCompteurelec"
                                            :key="index"
                                            v-model="num_comp_elec[index]"
                                            :label="'Numéro de compteur ELEC n°'+(index+1)"
                                        ></v-text-field>
                                        <v-btn
                                            depressed
                                            elevation="1"
                                            large
                                            :color='$colors[0]'
                                            class="ma-auto text-white"
                                            width="45%"
                                            @click="ajouterCompteurElec"
                                        >
                                            Ajouter
                                        </v-btn>
                                        <v-btn
                                            v-if="lengthCompteurelec > 0"
                                            depressed
                                            elevation="1"
                                            large
                                            class="ml-2 error ma-auto text-capitalize"
                                            width="45%"
                                            @click="supprimerCompteurElec"
                                        >
                                            Supprimer
                                        </v-btn>
                                        
                                    </v-col>
                                     <v-btn
                                    v-if="!hasGeneratePDF"
                                    style="width-button-responsive"
                                    @click="generatePdf"
                                    depressed
                                    elevation="1"
                                    large
                                    :color='$colors[0]'
                                    class="text-white align-self-end"
                                    >
                                    Examiner le document
                                </v-btn>
                                <v-btn
                                    v-if="hasGeneratePDF && isSend == 1"
                                    @click="enregistrer"
                                    depressed
                                    elevation="1"
                                    large
                                    :color='$colors[0]'
                                    class="px-6 text-white align-self-end"
                                    >
                                    Enregistrer 
                                </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </div>
    </v-card>

    <!-- entete du pdf -->
    <div style="position:relative; left:-400%; width:600px;">
      <p
        id="para"
        style="margin-left:50px; margin-top:105px;font-size:10px; max-width: 500px;"
      >
        Je soussigné {{ nom }} {{ prenom }} autorise gaz-elec-moinscher.fr a
        acceder aux données mentionnées ci-dessous, pour la société
        {{ raison }} &nbsp;au siren {{ siren }} et
        pour le/les point(s) de mesure suivant:
      </p>
    </div>
  </div>
  </div>
</template>

<script>
    import loadingComponent from "../components/ui/loading-component.vue"
    import axios from 'axios'
    import html2canvas from 'html2canvas'
    import jsPDF from 'jspdf'
    import dialogeModal from '../components/ui/dialogeModal.vue'

    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import 'jspdf-autotable'

    export default {
        name: 'AutorisatonDeCollectionDeDonnees',
        components: {
            dialogeModal,
            loadingComponent
        },
        data() {
            return {
                hasGeneratePDF : false,
                search: "",
                dialog: false,
                error: false,
                loading: false,
                messageDialoge: "L'e-mail a été envoyé avec succès",
                //enedis data
                enedisRecherchePointResult: null,
                missedCompteur: null,
                //radio button data for add compteur
                num_comp_gaz: [],
                lengthCompteurgaz: 0,
                lengthCompteurelec: 0,
                num_comp_elec: [],
                typeContrat: "gaz",
                //data for test exist siret and siren
                siretFound: null,
                siretExist: [],
                //insee response data
                inseeResponse: null,
                //data for pdf 
                RCS: '',
                siret: "",
                siren: "",
                adressePostale: "",
                raison: "",
                nom: "",
                prenom: "",
                raison: "",
                email: "",
                fonction: "",
                num_tel: "",
                //form data for upload file
                formData: null,
                //l'etat deximination de fichier
                examine_file: true,
                isSend: 0,
                //les rules du formulaire
                rules: {
                    required: value => !!value || 'Ce champ est obligatoire.',
                    isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                    
                    sirenLength: v => !!v==0 || v?.length == 9 || 'Le siren doit comporter 9 chiffres',
                    siretLength: v => !!v==0 || v?.length == 14 || 'Le siret doit comporter 14 chiffres',
                    isNumber: v => v?.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre',
                    compteurElecLength: v => v?.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                    isGazCompteur: v => (v?.match(/^(GI)[0-9]{6}$/) || (v?.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                    phoneLength: v => v?.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                    phoneisNumber: v => v?.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
                    margeMaxMin: v => (v<=10 && v>=2) || 'La marge doit être comprise entre 2 et 10',
                    
                     emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
                },
                verifyLength() {
                    return this.siret?.length == 14 || 'Le siret doit comporter 14 chiffres.'
                },
                siretAcceptNumber(){
                    return this.siret.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre'
                },
                siretInccorect: null,
            }
        },
        created() {
            this.typeUser = localStorage.getItem('typeUser')
        },
        methods: {
             // debut dans l'ajoute et suppression d'un compteur dans le formulaire
            ajouterCompteurGaz() {
                this.lengthCompteurgaz += 1
            },
            supprimerCompteurGaz() {
                this.num_comp_gaz.splice(this.lengthCompteurgaz, 1)
                this.lengthCompteurgaz -= 1
            },

            ajouterCompteurElec() {
                this.lengthCompteurelec += 1
            },
            supprimerCompteurElec() {
                this.num_comp_elec.splice(this.lengthCompteurelec, 1)
                this.lengthCompteurelec -= 1
                this.enedisRecherchePointResult= null;
                this.missedCompteur= null;
            },
            // fin dans l'ajoute et suppression d'un compteur dans le formulaire

            //debut test sur le type de choix du compteur GAZ ELEC GAZ&ELEC
            choisirElec() {
                this.lengthCompteurgaz = 0
                this.num_comp_gaz = []
            },
            choisirGaz() {
                this.lengthCompteurelec = 0
                this.num_comp_elec = []
            },
            // cette fonction vérifie si la sirène était correcte et elle renvoie la liste des sirets associés à cette sirène
            getSiretData() {
                this.$refs.formSiren.validate()
                if(this.$refs.formSiren.validate()){
                    
                    this.dialog = true
                    this.loading = true
                    this.error = false
                    
                    this.siretFound = 0
                    this.inseeResponse = null
                    this.siretExist = []
                    var formData = new FormData()
                    formData.append('siren', this.siren)
                    formData.append('token', this.getToken('token'))
                    axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'getListeSiret/',
                            method: 'POST',
                            data: formData,
                            
                    })
                    .then((res) => {
                        if (res.data['result'] === 1) {
                            if (res.data['value'] == 0) {
                                this.siretFound = 2
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Il y a trop de siret associé a ce siren merci de completer le SIRET manuellement"
                            } else if (res.data['value'] == 1) {
                                if(res.data['data'].length==0){
                                    this.siretFound = 0
                                    this.dialog = true
                                    this.error = true
                                    this.messageDialoge = "Votre siren est incorrect, veuillez vérifier vos données"

                                }else{
                                    this.siretFound = 1
                                    var j = 0
                                    for (var i = 0; i < res.data['data'].length; i++) {
                                        this.siretExist.push({name: res.data['data'][i].adresse, disable: true})
                                        this.siretExist.push({name: res.data['data'][i].siret, disable: false})
                                    }
                                    
                                    this.dialog = false
                                    this.loading = false
                                    this.error = false

                                }
                                //this.siretExist = res.data['data'][0].siret;
                            }else{
                                this.siretFound = 3
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Votre siren est incorrect, veuillez vérifier vos données"
                            }
                            this.loading = false
                        }else{
                            this.dialog = false
                            this.loading = false
                            this.error = false
                        }
                    })
                    .catch((err) => {
                        
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        console.log(err)
                    })
                }
            },
            //recherche toutes les données sur une siret choisie
            rechercheParSiret() {
                this.$refs.formSiret.validate()
                if(this.$refs.formSiret.validate()){
                    this.hasGeneratePDF = false;
                    
                    this.dialog = true
                    this.loading = true
                    this.error = false
                    var formData = new FormData()
                    formData.append('siret', this.siret)
                    formData.append('token', this.getToken('token'))
                    axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'getDonneParSiret/',
                            method: 'POST',
                            data: formData,
                            
                    }).then((res) => {
                        if (res.data.result == true) {
                            this.siretInccorect = false
                            this.raison = res.data.raison
                            this.siret = res.data.siret
                            this.siren = res.data.siren
                            this.adressePostale = res.data.adresse
                            this.RCS = res.data.rcs
                            if (res.data.infoPerson == true) {
                                this.email = res.data.AdresseEmail
                                this.num_tel = res.data.numTele
                                this.nom = res.data.nom
                                this.prenom = res.data.prenom
                            } else {
                                this.email = ''
                                this.num_tel = ''
                                this.nom = ''
                                this.prenom = ''
                            }
                            this.num_comp_gaz = ['']
                            this.num_comp_elec = ['']
                            this.elec = 'gaz'
                            this.inseeResponse = true
                            this.dialog = false
                            this.loading = false
                            this.error = false
                        }else{
                            this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                            this.dialog = true
                            this.loading = false
                            this.error = true
                        }
                        
                    }).catch((err) => {
                        this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                        this.dialog = true
                        this.loading = false
                        this.error = true
                    })
                }
                
            },
            verifPageEnd(pageHeight,doc, currentPage, currentY){
                
                if(currentY<50){
                    var page = doc.addPage()
                    var startingPositon = 750
                }else{
                    var page = currentPage
                    var startingPositon = currentY
                }
                return { page, startingPositon };
            },
            //la fonction appelle l'une des fonctions précédentes qui génèrent le type de leur pdf sellon
            async generatePdf() {
                this.$refs.formUpload.validate()
                if(this.$refs.formUpload.validate()){
                    this.hasGeneratePDF = true;
                    /*if (this.typeContrat === 'gaz') {
                        this.generatePdfGaz()
                    } else if (this.typeContrat === 'elec') {
                        this.generatePdfElec()
                    }else{
                        this.generatePdfElecAndGaz()
                    }*/
                    //recherche point enedis
                    
                    
                    this.dialog = true
                    this.loading = true
                    this.error = false

                    // convert the html to pdf
                    if (this.typeContrat === 'gaz') {
                       await this.generateACDGAZ()
                    } else if (this.typeContrat === 'elec') {
                        
                        /*formDonne.append('codePostale', this.dataFoundSociete.etablissement.codePostalEtablissement)
                        formDonne.append('typeVoie', this.dataFoundSociete.etablissement.typeVoieEtablissement)
                        formDonne.append('nomVoie', this.dataFoundSociete.etablissement.libelleVoieEtablissement)
                        formDonne.append('codeInsee', this.dataFoundSociete.etablissement.codeCommuneEtablissement)*/
                        var formDonne = new FormData()
                        formDonne.append('numCompteur', this.num_comp_elec)
                        formDonne.append('token', this.getToken('token'))
                        
                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                            method: 'POST',
                            data: formDonne,
                            
                        }).then(async (res) => {
                            if( res.data.result === 'permission'){
                                localStorage.removeItem('vendeurName')
                                localStorage.removeItem('typeUser')
                                localStorage.removeItem('token')
                                this.$router.push('/login')
                            }
                            if(res.data[0].result==='valide'){
                                this.enedisRecherchePointResult = res.data[0].result;
                                await this.generateACDELEC()
                            } else if (res.data[0].result === 'notValide') {
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.missedCompteur=res.data[0].compteurError;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "le compteur n°"+this.missedCompteur+" n'existe pas"
                            }else if(res.data[0].result==='dataErreur'){
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "veuillez vérifier vos données."
                            } else {
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Il semble qu'une erreur soit survenue !"                              
                            }
                        }).catch((err) => {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            console.log(err)
                        })
                    } else {
                        var formDonne = new FormData()
                        formDonne.append('numCompteur', this.num_comp_elec)
                        formDonne.append('token', this.getToken('token'))
                        
                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                            method: 'POST',
                            data: formDonne,
                            
                        }).then(async (res) => {
                            if( res.data.result === 'permission'){
                                localStorage.removeItem('vendeurName')
                                localStorage.removeItem('typeUser')
                                localStorage.removeItem('token')
                                this.$router.push('/login')
                            }
                            if(res.data[0].result==='valide'){
                            this.enedisRecherchePointResult = res.data[0].result;
                             await this.generateACDDUAL()
                            }else if(res.data[0].result==='notValide'){
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.missedCompteur=res.data[0].compteurError;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "le compteur n°"+this.missedCompteur+" n'existe pas"
                            }else if(res.data[0].result==='dataErreur'){
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "veuillez vérifier vos données comme l'adresse (ou) code insee."
                            }else{
                                this.hasGeneratePDF = false
                                this.dialog = false
                                this.loading = false
                                this.error = false
                                this.enedisRecherchePointResult = res.data[0].result;
                                this.dialog = true
                                this.error = true
                                this.messageDialoge = "Il semble qu'une erreur soit survenue !"                              
                            }
                        }).catch((err) => {
                            this.hasGeneratePDF = false
                            this.dialog = false
                            this.loading = false
                            this.error = false
                            console.log(err)
                        }).finally(()=>{
                            this.loading = false;
                        })
                    }
                }
            },
            async generateACDELEC(){
                
                var raison = this.raison
                var nom = this.nom
                var prenom = this.prenom
                var email = this.email
                var fonction = this.fonction

                var url0 = '/pdf/acd/acdSmart.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                const { width, height } = firstPage.getSize()
                var pageHeight = height
                var startingPositon=725
                firstPage.drawText("Je soussigné(e) "+ nom+" "+prenom, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                firstPage.drawText("en ma qualité de "+ fonction, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                var lineRaison = "dûment habilité(e) à représenter la société "+ raison
                var multiText = layoutMultilineText(lineRaison, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon -25
                firstPage.drawText("immatriculé(e) au RCS de "+ this.RCS, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :"+ this.siret, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à"+
                    " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                startingPositon = startingPositon - 25
                for(let i = 0; i < multiText.lines.length; i++) {
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon - 30
                firstPage.drawText('N° PDL(s)', {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                })  
                startingPositon = startingPositon - 20
                for (var i = 0; i < this.num_comp_elec.length; i++) {
                    firstPage.drawText('-' + this.num_comp_elec[i], {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })  
                    startingPositon = startingPositon - 15
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                }
                startingPositon = startingPositon - 25
                lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie "+
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 30
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                var DateAujourdhui = new Date();
                 DateAujourdhui = DateAujourdhui.getDate()+"/"+(DateAujourdhui.getMonth()+1)+"/"+DateAujourdhui.getFullYear()
                firstPage.drawText("le "+DateAujourdhui+" Signature + Tampon : ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                startingPositon = startingPositon - 50
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("SMART-ELECTRICITÉ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("(06.27.79.34.93)", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                /*firstPage.drawText("Cette autorisation reproduit celle du partenaire", {
                    x: 100,
                    y: startingPositon,
                    size: 6,
                    color: rgb(0, 0, 0),
                })*/
                 //fin contact
                const pdfBytes = await pageZeroDoc.save()
                var file = download(pdfBytes, 'ACD_'+this.raison+'_.pdf', 'application/pdf')
                     //create form data

                var formData = new FormData()
                const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
                var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file)=>{
                    return file
                })
                formData.append('siret', this.siret)
                formData.append('RCS', this.RCS)
                formData.append('siren', this.siren)
                formData.append('raison', raison)
                formData.append('adresse', this.adressePostale)
                formData.append('numTele', this.num_tel)
                formData.append('nom', nom)
                formData.append('prenom', prenom)
                formData.append('email', email)
                formData.append('type', 'ELEC')
                formData.append('numCompteurGaz', this.num_comp_gaz)
                formData.append('numCompteurElec', this.num_comp_elec)
                formData.append('pdf', pdfFile)
                formData.append('token', this.getToken('token'))
                this.formData = formData
                this.isSend = 1
                this.dialog = false
                this.loading = false
                this.error = false
            },
            async generateACDGAZ(){
                
                var raison = this.raison
                var nom = this.nom
                var prenom = this.prenom
                var email = this.email
                var fonction = this.fonction

                var url0 = '/pdf/acd/acdSmart.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                const { width, height } = firstPage.getSize()
                var pageHeight = height
                var startingPositon=725
                firstPage.drawText("Je soussignée "+ nom+" "+prenom, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                firstPage.drawText("en ma qualité de "+ fonction, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                var lineRaison = "dûment habilité(e) à représenter la société "+ raison
                var multiText = layoutMultilineText(lineRaison, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon -25
                firstPage.drawText("immatriculé(e) au RCS de "+ this.RCS, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :"+ this.siret, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à"+
                    " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                startingPositon = startingPositon - 25
                for(let i = 0; i < multiText.lines.length; i++) {
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon - 30
                firstPage.drawText('N° PCE(s)', {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                })  
                startingPositon = startingPositon - 20
                for (var i = 0; i < this.num_comp_gaz.length; i++) {
                    firstPage.drawText('-' + this.num_comp_gaz[i], {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })  
                    startingPositon = startingPositon - 15
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                }
                startingPositon = startingPositon - 25
                lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie "+
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 30
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                var DateAujourdhui = new Date();
                 DateAujourdhui = DateAujourdhui.getDate()+"/"+(DateAujourdhui.getMonth()+1)+"/"+DateAujourdhui.getFullYear()
                firstPage.drawText("le "+DateAujourdhui+" Signature + Tampon : ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                startingPositon = startingPositon - 50
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("SMART-ELECTRICITÉ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("(06.27.79.34.93)", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                
                 //fin contact
                const pdfBytes = await pageZeroDoc.save()
                download(pdfBytes, 'ACD_'+this.raison+'_.pdf', 'application/pdf')
                     //create form data
                var formData = new FormData()
                const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
                var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file)=>{
                    return file
                })
                formData.append('siret', this.siret)
                formData.append('RCS', this.RCS)
                formData.append('siren', this.siren)
                formData.append('raison', raison)
                formData.append('adresse', this.adressePostale)
                formData.append('numTele', this.num_tel)
                formData.append('nom', nom)
                formData.append('prenom', prenom)
                formData.append('email', email)
                formData.append('type', 'GAZ')
                formData.append('numCompteurGaz', this.num_comp_gaz)
                formData.append('numCompteurElec', this.num_comp_elec)
                formData.append('pdf', pdfFile)
                formData.append('token', this.getToken('token'))
                this.formData = formData
                this.isSend = 1
                this.dialog = false
                this.loading = false
                this.error = false
            },
            async generateACDDUAL(){
                
                var raison = this.raison
                var nom = this.nom
                var prenom = this.prenom
                var email = this.email
                var fonction = this.fonction

                var url0 = '/pdf/acd/acdSmart.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                const { width, height } = firstPage.getSize()
                var pageHeight = height
                var startingPositon=725
                firstPage.drawText("Je soussignée "+ nom+" "+prenom, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                firstPage.drawText("en ma qualité de "+ fonction, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -30
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                var lineRaison = "dûment habilité(e) à représenter la société "+ raison
                var multiText = layoutMultilineText(lineRaison, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon -25
                firstPage.drawText("immatriculé(e) au RCS de "+ this.RCS, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :"+ this.siret, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon -25
                firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à"+
                    " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                startingPositon = startingPositon - 25
                for(let i = 0; i < multiText.lines.length; i++) {
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                startingPositon = startingPositon - 30
                firstPage.drawText('N° PDL(s)', {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                })  
                firstPage.drawText('N° PCE(s)', {
                        x: 250,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                })  
                startingPositon = startingPositon - 20
                var lengthCompteur = 0
                if(this.num_comp_gaz.length> this.num_comp_elec.length){
                    lengthCompteur = this.num_comp_gaz.length
                }else{
                    lengthCompteur = this.num_comp_elec.length
                }
                for (var i = 0; i < lengthCompteur; i++) {
                    if(i< this.num_comp_gaz.length){
                        firstPage.drawText('-' + this.num_comp_gaz[i], {
                            x: 250,
                            y: startingPositon,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })  
                    }
                    if(i< this.num_comp_elec.length){
                        firstPage.drawText('-' + this.num_comp_elec[i], {
                            x: 100,
                            y: startingPositon,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })  
                    }
                    startingPositon = startingPositon - 15
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                }
                startingPositon = startingPositon - 25
                lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie "+
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
                var multiText = layoutMultilineText(lines, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 630 - 100, height: 1000  }
                })
                for(let i = 0; i < multiText.lines.length; i++) {
                    var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                    firstPage = resultatDeVerificationDeHeight.page
                    startingPositon = resultatDeVerificationDeHeight.startingPositon
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 50,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - 15
                }
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 30
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                var DateAujourdhui = new Date();
                 DateAujourdhui = DateAujourdhui.getDate()+"/"+(DateAujourdhui.getMonth()+1)+"/"+DateAujourdhui.getFullYear()
                firstPage.drawText("le "+DateAujourdhui+" Signature + Tampon : ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                startingPositon = startingPositon - 50
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("SMART-ELECTRICITÉ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                firstPage.drawText("(06.27.79.34.93)", {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 12
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText("Cette autorisation reproduit celle du partenaire", {
                    x: 100,
                    y: startingPositon,
                    size: 6,
                    color: rgb(0, 0, 0),
                })
                 //fin contact
                const pdfBytes = await pageZeroDoc.save()
                download(pdfBytes, 'ACD_'+this.raison+'_.pdf', 'application/pdf')
                    //create form data
                var formData = new FormData()
                const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
                var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file)=>{
                    return file
                })
                formData.append('siret', this.siret)
                formData.append('RCS', this.RCS)
                formData.append('siren', this.siren)
                formData.append('raison', raison)
                formData.append('adresse', this.adressePostale)
                formData.append('numTele', this.num_tel)
                formData.append('nom', nom)
                formData.append('prenom', prenom)
                formData.append('email', email)
                formData.append('type', 'DUAL')
                formData.append('numCompteurGaz', this.num_comp_gaz)
                formData.append('numCompteurElec', this.num_comp_elec)
                formData.append('pdf', pdfFile)
                formData.append('token', this.getToken('token'))
                this.formData = formData
                this.isSend = 1
                this.dialog = false
                this.loading = false
                this.error = false
            },
            async urltoFile(url, filename, mimeType){
                return (fetch(url)
                    .then(function(res){return res.arrayBuffer();})
                    .then(function(buf){return new File([buf], filename,{type:mimeType});})
                );
            },
            //envoyer le document et reinitiliser tout les valeur
            enregistrer() {
                
                this.dialog = true
                this.loading = true
                this.error = false

                //upload pdf file to php server le form data remplire dans les function generate pdf...
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'autorisationDeCollebctionDeDonner/',
                    method: 'POST',
                    data: this.formData,
                    
                }).then((res) => {
                    if (res.data.result == false) {
                        this.examine_file = false
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        this.dialog = true
                        this.loading = false
                        this.error = true
                        this.messageDialoge= "Une erreur s'est produite veuillez réessayer"
                    } else if (res.data.result == true) {
                        
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        this.dialog = true
                        this.loading = false
                        this.error = false
                        this.messageDialoge= "Cette demande a été enregistrée avec succès"
                        this.siretFound = 0
                        this.isSend = 0
                        this.inseeResponse = false
                        this.siren = null
                        this.nom = null
                        this.prenom = null
                        this.raison = null
                        this.email = null
                        this.num_tel = null
                        this.elec = null
                        this.num_comp_gaz = []
                        this.lengthCompteurgaz = 0
                        this.lengthCompteurelec = 0
                        this.num_comp_elec = []
                    } else if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                        //access denied
                    } else {
                        this.examine_file = false
                        
                        this.dialog = false
                        this.loading = false
                        this.error = false
                        this.dialog = true
                        this.loading = false
                        this.error = true
                        this.messageDialoge= "Une erreur s'est produite veuillez réessayer"
                    }
                }).catch((err) => {
                    console.log(err)
                    this.examine_file = false
                    
                    this.dialog = false
                    this.loading = false
                    this.error = false
                    this.dialog = true
                    this.loading = false
                    this.error = true
                    this.messageDialoge= "Une erreur s'est produite veuillez réessayer"
                })
            },
            // fonction qui crée la date du jour pour la mettre à la fin du fichier pdf "fait le"
            currentDateTime() {
                const current = new Date()
                const date =
                current.getDate() +
                '/' +
                (current.getMonth() + 1) +
                '/' +
                current.getFullYear()
                return date
            },
        }
    }
     
</script>
<style scoped>
::v-deep .v-label{
   margin:0px; 
}
</style>
<style scoped>
@media only screen (max-width:350px){
::v-deep .v-application .ml-2{
                margin-left:0px !important;
        }
}
</style>
<style>
@media only screen and (max-width:400px){
  .title{
    font-size:small,
  }
}
</style>
