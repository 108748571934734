<template>
    <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogProps==true">
                <v-dialog
                    v-model="dialogProps"
                    persistent
                    width="300"
                    height="250"
                    >
                    <v-card v-if="!errorProps && loadingProps==true" style="background-color:#103A5E">
                        <v-card-text class="pa-0">
                            <div style="height:150px" class="pa-4 d-flex flex-row justify-content-center align-items-center text-center">
                                <svg width="150px" fill="none" height="75px" viewBox="0 0 48 48"  version="1.1" xmlns="http://www.w3.org/2000/svg"  >
                                    <defs>
                                        <filter id="iconfilter" primitiveUnits="objectBoundingBox" >
                                    <feFlood flood-color="white"/>
                                        
                                        
                                        <feOffset>
                                            <animate attributeName="dy" from="1" to="0" dur="3s" repeatCount="indefinite" />
                                        </feOffset> 
                                        
                                        <feComposite operator="in" in2="SourceGraphic" />
                                        <feComposite operator="over" in2="SourceGraphic" />
                                        
                                        </filter>
                                    </defs>
                                    <g id="userIcon" filter="url(#iconfilter)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0699 26.2851C14.0324 27.4611 16.4803 31.8176 17 34H31C31.5198 31.8193 33.9648 27.4628 34.9273 26.2885C36.5762 24.2717 37.6099 21.8396 37.9094 19.2725C38.209 16.7053 37.7621 14.1074 36.6204 11.7782C35.4787 9.44891 33.6885 7.48299 31.4561 6.10687C29.2237 4.73075 26.6398 4.00041 24.0022 4C21.3646 3.99959 18.7806 4.72911 16.5477 6.10453C14.3149 7.47995 12.524 9.44531 11.3816 11.7742C10.2391 14.1031 9.79137 16.7008 10.0901 19.2681C10.3888 21.8354 11.4217 24.2678 13.0699 26.2851ZM25 12L19 21H23L23 27L29 18H25V12Z" fill="#103A5E"/>
                                    <path d="M17 36H31V38H17V36Z" fill="#103A5E"/>
                                    <path d="M17 40H31V42C31 43.1046 30.1046 44 29 44H19C17.8954 44 17 43.1046 17 42V40Z" fill="#103A5E"/>
                                        
                                            </g>
                                    </svg>
                            </div>
                        </v-card-text>
                        <div> 
                        </div>
                    </v-card>
                    <v-card v-else>
                             <v-alert class="ma-0" v-if="errorProps==true && loadingProps==false" type="error">
                                <div class="d-flex flex-column">
                                    <p>{{messageDialogeProps}}</p>
                                    <v-btn class="align-self-end white--text" v-if="loadingProps==false"
                                        text
                                        @click="$emit('closeDialog',false)"
                                    >
                                        FERMER
                                    </v-btn>
                                </div>
                            </v-alert>
                            <v-alert v-if="errorProps==false && loadingProps==false" type="success">
                                <div class="d-flex flex-column">
                                    <p>{{messageDialogeProps}}</p>
                                    <v-btn class="align-self-end white--text" v-if="loadingProps==false"
                                        text
                                        @click="$emit('closeDialog',false)"
                                    >
                                        FERMER
                                    </v-btn>
                                </div>
                            </v-alert>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
</template>
<script>
export default {
    name:'loadingComponent',
    props:['dialogProps','messageDialogeProps','loadingProps',"errorProps"],
    data(){
        return{
            dialog:this.dialogProps,
            loading:this.loadingProps,
            error:this.errorProps,
            messageDialoge:this.messageDialogeProps
        }
    },
    watch:{
        /*dialogProps:function(newvalue,oldvalue){
            this.dialog = newValue;
        },
        errorProps:function(newValue,oldValue){
            this.error = newValue;
        },
        messageDialogeProps:function(newValue,oldValue){
            this.messageDialoge = newValue;
        },
        loadingProps:function(newValue,oldValue){
            this.loading = newValue;
        }*/
    },
}
</script>