<template>
    <div>  
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Extraction du contrat
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div>
            <v-btn
            @click="generatePdfNode"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>    
        import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'primeoMulti',        
                props: {
            optionTarifaire: '',
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
            codePremio: '',
        },
        data: () => ({
            
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
             generatedPdf: null,
             type: '',
             positionPage1: 1,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             
             data:{
                 dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+"/"+new Date().getFullYear(),
                 dateDebut: "",
                 dateFin: "",
                 pdl: "",
                 siret: "", 
                 codeNaf: "", 
                 adresseSociete: "", 
                 adresseCompteur: "", 
                 nom: "",
                 prenom: "", 
                 email: "", 
                 numTele: "", 
                 consommationAnuuelle: "",
                 typeCompteur: "",
                 kva: "",
                kvaBase: '',
                kvaHp: '',
                kvaHc: '',
                kvaPte: '',
                kvaHce: '',
                kvaHpe: '',
                kvaHch: '',
                kvaHph: '',

                 prixPointe: "",
                 prixHPH: "",
                 prixHCH: "",
                 prixHPE: "",
                 prixHCE: "",
                 periodeDebut: [],
                 periodeFin: [],
                 stringLesPeriode: '',
                 aboPeriode: '',
                 ceePeriode: '',
                 capaPeriode: '',
                 idSociete: "",
                 raison: '',
             },
        }),
        created() {
           // this.verifyApplicationDataSecurity()
        },
        methods: {
            async getOppid(){
                this.data.idSociete = this.codePremio+""
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune
                }
                this.data.nbrMonth = this.donner.nbrMonth+""
                if(this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur!=''){
                    this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
                }else{
                    this.data.typeCompteur = this.optionTarifaire
                }
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                
                this.data.nbrYears = this.donner.nbrYears+""
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.raison = this.societe.Raison+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                
                this.data.kva = this.donnerEnedis.donneTechniqueInfo[0].data.kva+""
                this.data.kvaBase= this.donner.kvaBase+""
                this.data.kvaHp= this.donner.kvaHp+""
                this.data.kvaHc= this.donner.kvaHc+""
                this.data.kvaPte= this.donner.kvaPte+""
                this.data.kvaHce= this.donner.kvaHce+""
                this.data.kvaHpe= this.donner.kvaHpe+""
                this.data.kvaHch= this.donner.kvaHch+""
                this.data.kvaHph= this.donner.kvaHph+""
                if(isNaN(this.data.kvaPte) == true){
                    this.data.kvaPte = ""
                }

                this.data.prixHPH = this.donner.unitairehph+""
                this.data.prixHCH = this.donner.unitairehch+""
                this.data.prixHPE = this.donner.unitairehpe+""
                this.data.prixHCE = this.donner.unitairehce+""
                this.data.prixHC = this.donner.unitairehc+""
                this.data.prixHP = this.donner.unitairehp+""
                this.data.prixBASE = this.donner.unitaire+""
                this.data.prixPointe = this.donner.unitairepointe+""
                if(isNaN(this.data.prixPointe) == true){
                    this.data.prixPointe = ""
                }
                
                this.data.aboPeriode = ""
                this.data.ceePeriode = this.donner.listCee+""
                this.data.capaPeriode = this.donner.listCapa+""
                if(this.data.capaPeriode.indexOf(",")!=-1){
                   this.data.capaPeriode = this.data.capaPeriode.split(',').join(' ')
                }
                if(this.data.ceePeriode.indexOf(",")!=-1){
                   this.data.ceePeriode = this.data.ceePeriode.split(',').join(' ')
                }
                if(this.donner.type.includes('fixe')){
                    this.type = "fixe"

                }else{
                    this.type = "arenh"
                }
                
            },
            async generatePdfNode(){
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    codePremio: this.codePremio,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/primeoContratMulti',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    for(var i =0; i<res.data.nbrFile; i++){
                        var doc = await PDFDocument.load(res.data.files[i])
                        doc = await doc.save()
                        download(doc, this.numCompteur+'_PrimeoEnergie_'+i+'_'+this.societe.Raison+'_.pdf', 'application/pdf')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
                this.$emit("readonlyExpand",false);
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
            getPeriods(date1, date2){
                date1 = date1.split("/").reverse().join("-")
                date2 = date2.split("/").reverse().join("-")
                var pd = []
                var pf = []
                
                var m1 = new Date(date1).getMonth()+1
                var d1 = new Date(date1).getDate()
                var y1 = new Date(date1).getFullYear()

                
                var m2 = new Date(date2).getMonth()+1
                var d2 = new Date(date2).getDate()
                var y2 = new Date(date2).getFullYear()
                var tmpDateDebut = ""
                var tmpDateFin = ""
                 
                if(y2 != y1){
                    date1 = date1.split("-").reverse().join("/")
                    pd.push(date1)
                    for (var i=y1; i<=y2; i++ ){
                        if(i != y2){
                            var s = i+"-12-31"
                            s = s.split("-").reverse().join("/")
                            pf.push(s)
                            s= (i+1)+"-01-01"
                            s = s.split("-").reverse().join("/")
                            pd.push(s)
                        }
                    }
                    
                    date2 = date2.split("-").reverse().join("/")
                    pf.push(date2)
                }else{
                    
                    date1 = date1.split("-").reverse().join("/")
                    date2 = date2.split("-").reverse().join("/")
                    pd.push(date1)
                    pf.push(date2)
                }
                
                var periode = ""
                for(var i =0; i < pd.length; i++){
                    periode=periode+(" Du "+pd[i]+" au "+pf[i])
                }
                this.data.periodeDebut = pd
                this.data.periodeFin = pf
                this.data.dateDebut = date1
                this.data.dateFin = date2
                this.data.stringLesPeriode = periode
            },
        },
    }
</script>